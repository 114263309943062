import React from 'react';
import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody } from "@mui/material";

export default function ParametersTable({ rows }: { rows: { name: string; description: string }[] }) {
    return (
        <TableContainer 
            component={Paper} 
            sx={{ 
                display: 'flex', 
                width: '100%',
                overflowX: 'auto', // Горизонтальная прокрутка для маленьких экранов
            }}
        >
            <Table 
                sx={{ 
                    width: '100%', // Занимает всю доступную ширину
                    '& .MuiTableCell-root': { 
                        padding: { xs: '8px', sm: '16px' },
                        fontSize: { xs: '0.8rem', sm: '1rem' },
                    },
                }}
                aria-label="responsive table"
            >
                <TableHead>
                    <TableRow>
                        <TableCell sx={{ fontWeight: 'bold' }}>Name</TableCell>
                        <TableCell align="right" sx={{ fontWeight: 'bold' }}>Description</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <TableRow
                            key={row.name}
                            sx={{
                                '&:last-child td, &:last-child th': { border: 0 },
                                '& .MuiTableCell-root': {
                                    whiteSpace: 'normal', // Разрешаем перенос текста
                                    wordWrap: 'break-word', // Перенос длинных слов
                                }
                            }}
                        >
                            <TableCell component="th" scope="row">
                                {row.name}
                            </TableCell>
                            <TableCell align="right">{row.description}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
