import CommonPage from "../Common/CommonPage";
import VersionsComponent from "./VersionsComponent";

export default function VersionsPage(){
    let main;
    main = (
        <VersionsComponent/>
    );
    return(
        <CommonPage main={main} />
    );
}