import { Container, Stack, Typography } from "@mui/material";
import ParametersTable from "./ParametersTable";


export default function BasicProcessingParameters() {
    const rows = [
        { name: 'clean_crop', description: 'Cropping an image to enhance composition and focus on main subjects' },
        { name: 'clean_touch', description: 'Resetting exposure settings when reprocessing to avoid cumulative errors' },
        { name: 'clean_evc', description: 'Exposure compensation to improve lighting in bright and dark areas' },
        { name: 'clean_brigh', description: 'Adjusting image brightness for better visibility of details' },
        { name: 'clean_sharpen', description: ' Increasing clarity of details, especially in textures' },
        { name: 'clean_hue', description: 'Adjusting hues for more accurate color representation' },
        { name: 'clean_color', description: 'General color correction to improve saturation and balance' },
        { name: 'clean_filter', description: 'Applying filters to create artistic effects and change the mood of the image' },
        { name: 'clean_saturation', description: 'Increasing or decreasing color saturation in the image' },
        { name: 'clean_stabilization', description: 'Stabilizing the image to reduce blur from camera shake' },
        { name: 'clean_contrast', description: 'Adjusting contrast to improve the separation of objects from the background' },
        { name: 'clean_gamma', description: 'Adjusting gamma for improved brightness and depth of the image' },
        { name: 'clean_temp', description: 'Changing color temperature for a warmer or cooler tone' },
        { name: 'clean_shadows', description: 'Enhancing detail in shadows to avoid loss of information in dark areas' },
        { name: 'clean_highlights', description: 'Adjusting the brightness of highlights for better light balance' },
        { name: 'clean_black', description: 'Adjusting black levels to increase image depth' },
    
    ];

    return(
        <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'left',
          pt: { xs: 4, sm: 5 },
          pb: { xs: 3, sm: 8 },
          borderBottom: '2px solid',
          borderColor: 'divider'
        }}
      >
        <Stack spacing={2} useFlexGap sx={{ width: { xs: '100%', sm: '70%' } }}>
          <Typography
          component="h2"
          variant="h4"
          gutterBottom
          sx={{ color: 'text.primary', textAlign: 'left' }}
        >
          <b>Additional Processing Parameters</b>
        </Typography>
          <Typography
            textAlign="left"
            color="text.secondary"
            sx={{ alignSelf: 'left', fontSize: '16px' }}
          >
            This section includes supplementary settings that expand on basic image processing capabilities. Below are additional parameters, used primarily for fine-tuning and patching, with brief descriptions of their functions:          </Typography>
          <ParametersTable rows={rows}/>
        </Stack>
        </Container>
    );
}