import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import { useNavigate } from 'react-router-dom';


export default function FAQ() {
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const navigate = useNavigate();


  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <Container
      id="faq"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Typography
        component="h2"
        variant="h4"
        sx={{
          color: 'text.primary',
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'center' },
        }}
      >
        Frequently asked questions
      </Typography>
      <Box sx={{ width: '100%' }}>
      <Accordion
          expanded={expanded === 'panel4'}
          onChange={handleChange('panel4')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4d-content"
            id="panel4d-header"
          >
            <Typography component="h3" variant="subtitle2">
            What are the benefits of using GCam over my stock camera app?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ maxWidth: { sm: '100%', md: '90%' }, textAlign: 'left' }}
            >
              GCam provides superior image processing, particularly in features like HDR+, Night Sight, and Super Res Zoom. These features help deliver better dynamic range, clearer low-light images, and improved zoom quality compared to most stock camera apps. Additionally, GCam often handles colors more accurately and reduces noise, especially in challenging conditions like low light.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel1'}
          onChange={handleChange('panel1')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1d-content"
            id="panel1d-header"
          >
            <Typography component="h3" variant="subtitle2">
            Is GCam compatible with my phone?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ maxWidth: { sm: '100%', md: '90%', textAlign: 'left' } }}
            >
              GCam is compatible with many Android devices, but compatibility depends on whether your phone has Camera2 API enabled, which is essential for GCam to function. Popular devices from brands like Xiaomi, OnePlus, Samsung, Google Pixel, Realme, and others often have compatible versions. To check compatibility, you can use apps like Camera2 API Probe to see if your device supports the required features.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel2'}
          onChange={handleChange('panel2')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2d-content"
            id="panel2d-header"
          >
            <Typography component="h3" variant="subtitle2">
            How do I install GCam on my Android device?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
          <Typography variant="body2"
              gutterBottom
              sx={{ maxWidth: { sm: '100%', md: '90%', textAlign: 'left' } }}>
            If you're experiencing issues with installation or setup, please refer to our  
            <Link href="/installation-bsg" underline="hover" sx={{ fontWeight: 'bold', color: 'primary.main' }}>
               {" "} Installation Guide {" "}
            </Link> 
             for step-by-step instructions and troubleshooting tips.
        </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel3'}
          onChange={handleChange('panel3')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel3d-content"
            id="panel3d-header"
          >
            <Typography component="h3" variant="subtitle2">
            How do I update GCam to the latest version?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ maxWidth: { sm: '100%', md: '90%', textAlign: 'left' } }}
            >
              Download the updated APK from our official page. Simply install the APK as you would any app, and it will overwrite the previous version without deleting your settings.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel4'}
          onChange={handleChange('panel4')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4d-content"
            id="panel4d-header"
          >
            <Typography component="h3" variant="subtitle2">
            How can I join the GCam community for updates and feedback?
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant="body2"
              gutterBottom
              sx={{ maxWidth: { sm: '100%', md: '90%', textAlign:'left' } }}
            >
              <Typography variant="body2" gutterBottom>
  You can join the GCam community by participating in our official <a href="https://t.me/Channel_MGC_BSG" target="_blank" rel="noopener noreferrer">Telegram channel</a> and <a href="https://t.me/PublicGroup_MGC_BSG" target="_blank" rel="noopener noreferrer">chat</a> for real-time updates and discussions. Here, you can get the latest GCam versions, tips from other users, and direct feedback on any issues you might face.
</Typography>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Typography 
            textAlign="start" 
            sx={{ alignSelf: 'start', fontSize: '17px'}} 
            style={{ cursor: 'pointer' }} 
            onClick={() => navigate('/faq-bsg')}
        >
            <SubdirectoryArrowRightIcon fontSize='small'/> <b> See full FAQ list</b>
        </Typography>
      </Box>
    </Container>
  );
}