import CommonPage from "../Common/CommonPage";
import PrivacyPolicyComponent from "./PrivacyPolicyComponent";

export default function PrivacyPolicy() {
    let main;
    main = (
      <PrivacyPolicyComponent/>
    );
    return(
        <CommonPage main={main}/>
    );
}
