import { alpha, Box, Container, Stack, Typography } from "@mui/material";
import CommonPage from "../Common/CommonPage";
import ParametersIntroduction from "./ParametersIntroduction";
import BasicProcessingParameters from "./BasicProcessingParameters";
import AdvancedSettings from "./AdvancedSettings";
import Footer from "../Footer/Footer";


export default function ParametersGuide() {
    let main;

    main = (
        <Box
      id="hero"
      sx={(theme) => ({
        width: '100%',
        backgroundImage:
          theme.palette.mode === 'light'
            ? 'linear-gradient(180deg, #CEE5FD, #FFF)'
            : `linear-gradient(#02294F, ${alpha('#090E10', 0.0)})`,
        backgroundSize: '100% 20%',
        backgroundRepeat: 'no-repeat',
      })}
    >
        <ParametersIntroduction/>
        <BasicProcessingParameters/>
        <AdvancedSettings/>
        <Footer/>
    </Box>
    );
    return(
        <CommonPage main={main}/>
    );
}