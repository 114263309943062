import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import TelegramIcon from '@mui/icons-material/Telegram';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles'; 

const logoStyle = {
  width: '140px',
  height: 'auto',
};


function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" mt={1}>
      {'Copyright © '}
      <Link href="/">BSG&nbsp;</Link>
      {new Date().getFullYear()}
    </Typography>
  );
}

export default function Footer() {
    const theme = useTheme();  // Получаем текущую тему
  const navigate = useNavigate();
  
  const isDarkMode = theme.palette.mode === 'dark';
  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 4, sm: 8 },
        pt: { xs: 8, sm: 10 },
        textAlign: { sm: 'center', md: 'left' },
        borderTop: '1px solid',
        borderColor: 'divider'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 4,
            minWidth: { xs: '100%', sm: '60%' },
            justifyContent: "left"
          }}
        >
          <Box sx={{ width: { xs: '100%', sm: '60%' }, alignItems: "left" }}>
            <Box sx={{ ml: '-15px', paddingBottom: "3%" }}>
              <img
                src={
                  isDarkMode
                    ? `${process.env.PUBLIC_URL}/bsglogo-dark.png`
                    : `${process.env.PUBLIC_URL}/bsglogo.png`
                }
                style={logoStyle}
                alt="logo of sitemark"
                
              />
              
            </Box>
            <Typography variant="body2" fontWeight={600} gutterBottom>
              Social media
            </Typography>
            <Typography variant="body2" color="text.secondary" mb={2}>
              Subscribe to our Telegram channel for weekly updates.
            </Typography>
            <Stack direction="row" spacing={1} useFlexGap sx={{ pt: {xs: 7}}}>
              <Button variant="contained" color="primary" sx={{ flexShrink: 0 }} onClick={() => window.open('https://t.me/Channel_MGC_BSG')}>
                Subscribe
              </Button>
            </Stack>
          </Box>
        </Box>
      </Box>
      
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          pt: { xs: 4, sm: 8 },
          width: '100%',
          borderTop: '1px solid',
          borderColor: 'divider',
        }}
      >
        <div>
          <Link color="text.secondary" href="/privacypolicy">
              Privacy Policy
            </Link>
          <Copyright />
        </div>
        <Stack
          direction="row"
          justifyContent="left"
          spacing={1}
          useFlexGap
          sx={{
            color: 'text.secondary',
          }}
        >
          <IconButton
            color="inherit"
            href="https://t.me/Channel_MGC_BSG"
            aria-label="GitHub"
            sx={{ alignSelf: 'center' }}
          >
            <TelegramIcon />
          </IconButton>
        </Stack>
      </Box>
    </Container>
  );
}