import * as React from 'react';
import Introduction from './Home/Introduction';
import CommonPage from './Common/CommonPage';
import Footer from './Footer/Footer';
import WhyDownloadPart from './Home/WhyDownloadPart';
import LogoCollection from './Home/LogoCollection';
import AboutGcam from './Home/AboutGcam';
import FAQ from './Home/FAQ';


export default function LandingPage() {
  let main;
  main = (
    <>
    <Introduction/>
    <LogoCollection/>
    <AboutGcam/>
    <WhyDownloadPart/>
    <FAQ/>
    <Footer/>
    </>
  );
  return (
   <CommonPage main={main}/>
  );
}