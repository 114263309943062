import { Box, Breadcrumbs, Container, Link, Stack, Typography, alpha } from "@mui/material";
import { useState, useEffect, Key } from "react";
import { useParams } from "react-router-dom";
import DownloadIcon from '@mui/icons-material/Download';
import convertDate from "../Helpers/convertDate";
import convertFullDate from "../Helpers/convertFullDate";
import IFile from "../Interfaces/IFile";

export default function VersionElement() {
    const [version, setVersion] = useState<any>({});
    const [files, setFiles] = useState<IFile[]>([]);
    const params = useParams();
    const versionId = Object.values(params);
    const apiUrl = process.env.REACT_APP_API_URL;
    
    const processDescription = (description: string | undefined | null) => {
        if (typeof description === 'string') {
            const cleanedDescription = description.replace(/\\"/g, '"').trim();
            const [title, ...items] = cleanedDescription.split(/-\s+/);
    
            if (!items.length) {
                return { title: title.trim(), formattedItems: [] };
            }
    
            // Форматируем каждый элемент, добавляя дефис в начало и убирая лишние пробелы
            const formattedItems = items.map(item => `- ${item.trim()}`);
    
            return { title: title.trim(), formattedItems };
        }
    
        return { title: '', formattedItems: [] };
    };
    const { title, formattedItems } = processDescription(version.description);
    useEffect(() => {
        const fetchGetAll = async () => {
            fetch(`${apiUrl}/api/Version/Get/${versionId}`, {
                mode: 'cors',
                method: 'GET',
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json'
                },
                credentials: 'include'
                }).then(res => res.json())
                .then(res => {
                    setVersion(res);
                });
        }
        fetchGetAll();

        const getFiles = async () => {
            fetch(`${apiUrl}/api/File/GetFilesByVersion/${versionId}`, {
                mode: 'cors',
                method: 'GET',
                headers: {
                    'Accept': 'application/json, text/plain, */*',
                    'Content-Type': 'application/json'
                },
                credentials: 'include'
                }).then(res => res.json())
                .then(res => {
                    setFiles(res.files);
                });
        }
        getFiles();
    }, []);
    
      const handleClick = (url: string) => {
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.target = '_blank';
        anchor.rel = 'noopener noreferrer';
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
      };
      
      const cleanVersionName = (name: string | undefined) => {
        return name?.replace(/\[r\]|\[\/r\]/g, '') || '';
      };
      
      

    return(
        <Container
            sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: { xs: 14, sm: 20 },
          pb: { xs: 8, sm: 12 },
        }}  
        >
            <Box sx={{ alignSelf: 'flex-start', pb: "4%" }}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" href="/">
                        Home
                    </Link>
                    <Link underline="hover" color="inherit" href="/versions-bsg">
                        All BSG versions
                    </Link>
                    <Typography color="text.primary">{cleanVersionName(version.name)}</Typography>
                </Breadcrumbs>
            </Box>
            <Typography
                variant="h1"
                sx={{
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                alignSelf: 'center',
                textAlign: 'center',
                fontSize: {
                  xs: '2rem', // For mobile devices
                  sm: '2.5rem', // For small screens
                  md: '3rem', // For medium screens
                  lg: '3.5rem', // For large screens
                  xl: '4rem', // For extra large screens
                },
                paddingBottom: '5%'
                }}
            >
                {cleanVersionName(version.name)}
            </Typography>
            <Box sx={{ width: '100%', alignSelf: 'flex-start', paddingBottom: '2%' }}>
                <Typography textAlign="left" fontSize="18px">
                    <b>Download:</b>
                </Typography>
                {files.map((file) => (
                    <Typography
                        key={file.fileId}
                        textAlign="left"
                        color="text.secondary"
                        fontSize="18px"
                    >
                        <DownloadIcon />{' '}
                        <Link
                            component="button"
                            variant="body2"
                            underline="hover"
                            onClick={(e) => handleClick(file.presignedUrl)}
                            sx={{ textDecoration: 'none', color: 'inherit', cursor: 'pointer' }}
                        >
                            {file.fileName}
                        </Link>
                    </Typography>
                ))}
            </Box>
            <Box sx={{ width: '100%', alignSelf: 'flex-start', paddingTop: '5%' }}>
                <Typography textAlign="left" fontSize="18px">
                    <b>Release Date:</b> {convertFullDate(version.date)}
                </Typography>
            </Box>
            <Box sx={{ width: '100%', alignSelf: 'flex-start', paddingTop: '2%' }}>
                <Typography textAlign="left" fontSize="18px">
                    <b>Description:</b> 
                </Typography>
                <Typography textAlign="left" fontSize="18px" paddingTop="2%">
                    <Typography variant="h6" component="div" style={{ fontStyle: 'italic' }}>
                        {title}
                    </Typography>
                    {formattedItems.length > 0 ? (
                        formattedItems.map((item, index) => (
                            <div key={index}>{item}</div>
                        ))
                    ) : (
                        <div>-</div>
                    )}
                </Typography>
            </Box>
            
      </Container>
    );
}