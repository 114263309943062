import * as React from 'react';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { Box, Collapse, Container, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import TuneIcon from '@mui/icons-material/Tune';
import RecommendedParametersList from './RecommendedParametersList';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';


  const StyledBox = styled('div')(({ theme }) => ({
    alignSelf: 'left',
    width: '100%',
    marginTop: theme.spacing(8),
    borderRadius: (theme.vars || theme).shape.borderRadius,
    outline: '6px solid',
    outlineColor: 'hsla(220, 25%, 80%, 0.2)',
    border: '1px solid',
    borderColor: (theme.vars || theme).palette.grey[200],
    boxShadow: '0 0 12px 8px hsla(220, 25%, 80%, 0.2)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'hsla(0, 0%, 95%, 0.8)', 
    color: theme.palette.text.primary, 
    fontSize: '1.5rem', 
    
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(10),
    },
    
    ...theme.applyStyles('dark', {
      boxShadow: '0 0 24px 12px hsla(210, 100%, 25%, 0.2)',
      background: 'hsla(0, 0%, 10%, 0.8)', 
      outlineColor: 'hsla(220, 20%, 42%, 0.1)',
      borderColor: (theme.vars || theme).palette.grey[700],
      color: theme.palette.text.secondary,
    }),
  }));

  const portrait = [
    {
      primary: "clean_crop",
      secondary: "Crop the image to bring the subject closer and refine composition."
    },
    {
      primary: "clean_bright",
      secondary: "Adjust brightness to ensure the face is well-lit."
    },
    {
      primary: "clean_portrait",
      secondary: "Activates optimized settings for portraits, enhancing skin tones and adding natural blur to the background."
    },
    {
      primary: "clean_bokeh",
      secondary: "Enhances background blur to make the subject stand out."
    },
    {
      primary: "clean_sharpen",
      secondary: "Adds sharpness to facial details, particularly the eyes and hair."
    },
    {
      primary: "clean_color",
      secondary: "Boosts colors to make skin tones more vibrant yet natural."
    },
    {
      primary: "clean_contrast",
      secondary: "Balances the contrast for a soft but defined look."
    },
  ];

  const landscape = [
    {
      primary: "clean_evc",
      secondary: "Adjusts exposure compensation to avoid overly bright skies or dark shadows."
    },
    {
      primary: "clean_hdr",
      secondary: "Activates HDR to enhance dynamic range, capturing more detail in highlights and shadows."
    },
    {
      primary: "clean_color",
      secondary: "Enhances color saturation, making the landscape more vibrant."
    },
    {
      primary: "clean_stabilization",
      secondary: "Reduces blur for sharper landscape images, especially if shooting handheld."
    },
    {
      primary: "clean_shadows",
      secondary: "Lifts shadowed areas to reveal details in darker parts of the image."
    },
    {
      primary: "clean_sharpen",
      secondary: "Increases sharpness to bring out texture in elements like rocks, trees, and water."
    },
    {
      primary: "clean_contrast",
      secondary: "Adds contrast for a more dramatic and vivid look."
    },
  ];
  const night = [
    {
      primary: "clean_night",
      secondary: "Activates the night mode for optimized low-light performance."
    },
    {
      primary: "clean_iso",
      secondary: "Adjusts ISO sensitivity to capture more light in dark settings."
    },
    {
      primary: "clean_denoise",
      secondary: "Reduces noise, helping to eliminate graininess from high ISO values."
    },
    {
      primary: "clean_bright",
      secondary: "Adds extra brightness for improved visibility of darker areas."
    },
    {
      primary: "clean_shadows",
      secondary: "Lifts shadow levels to bring out details without overexposing bright areas."
    },
    {
      primary: "clean_highlights",
      secondary: "Controls bright spots, preventing overexposure from artificial lights."
    },
    {
      primary: "clean_temp",
      secondary: "Adjusts color temperature for more accurate colors under artificial lighting."
    },
  ];
  const macro = [
    {
      primary: "clean_macro",
      secondary: "Activates macro settings to capture close-up details with clarity."
    },
    {
      primary: "clean_sharpen",
      secondary: "Adds sharpness, ensuring intricate details are visible."
    },
    {
      primary: "clean_color",
      secondary: "Enhances colors for more vivid representation of fine details."
    },
    {
      primary: "clean_contrast",
      secondary: "Adds contrast to highlight the separation between the subject and background."
    },
    {
      primary: "clean_brightness",
      secondary: "Adjusts brightness to avoid overly dark areas in close-up shots."
    },
    {
      primary: "clean_saturation",
      secondary: "Boosts saturation for a richer representation of color."
    },
    {
      primary: "clean_stabilization",
      secondary: "Reduces camera shake for clearer, sharper macro images."
    },
  ];
  const actionSettings = [
    {
      primary: "clean_fps",
      secondary: "Increases frames per second (for video) to capture fast movement."
    },
    {
      primary: "clean_stabilization",
      secondary: "Activates stabilization to reduce blur caused by movement."
    },
    {
      primary: "clean_shutter_speed",
      secondary: "Increases shutter speed for sharper images of moving subjects."
    },
    {
      primary: "clean_sharpen",
      secondary: "Enhances details, making moving subjects appear crisp."
    },
    {
      primary: "clean_brightness",
      secondary: "Boosts brightness to compensate for fast shutter speeds."
    },
    {
      primary: "clean_contrast",
      secondary: "Adds contrast to make subjects stand out."
    },
    {
      primary: "clean_temp",
      secondary: "Adjusts color temperature based on lighting conditions (e.g., cooler for indoor, warmer for outdoor)."
    },
  ]; 
  const food = [
    {
      primary: "clean_color",
      secondary: "Boosts color accuracy to represent the dish’s true colors."
    },
    {
      primary: "clean_saturation",
      secondary: "Increases saturation for more vibrant colors."
    },
    {
      primary: "clean_brightness",
      secondary: "Adjusts brightness to create a well-lit image."
    },
    {
      primary: "clean_sharpen",
      secondary: "Adds sharpness to bring out the texture of the food."
    },
    {
      primary: "clean_temp",
      secondary: "Adjusts color temperature to add warmth and make food look more appetizing."
    },
    {
      primary: "clean_contrast",
      secondary: "Enhances contrast to make the food stand out against the background."
    },
  ];
  const architecture = [
    {
      primary: "clean_sharpen",
      secondary: "Enhances details for sharp architectural lines and textures."
    },
    {
      primary: "clean_evc",
      secondary: "Adjusts exposure compensation to control brightness in high-contrast settings."
    },
    {
      primary: "clean_color",
      secondary: "Maintains accurate color representation, especially for exterior shots."
    },
    {
      primary: "clean_contrast",
      secondary: "Increases contrast for a crisp, dramatic effect."
    },
    {
      primary: "clean_gamma",
      secondary: "Adjusts gamma to improve image depth."
    },
    {
      primary: "clean_highlights",
      secondary: "Reduces overexposure of bright areas, like windows or lights."
    },
  ];
  const street = [
    {
      primary: "clean_evc",
      secondary: "Adjusts exposure to handle variable lighting in urban environments."
    },
    {
      primary: "clean_shadows",
      secondary: "Lifts shadow details to reveal textures in darker areas."
    },
    {
      primary: "clean_brightness",
      secondary: "Ensures the scene is well-lit without overexposing."
    },
    {
      primary: "clean_sharpen",
      secondary: "Adds subtle sharpness to highlight textures and details."
    },
    {
      primary: "clean_contrast",
      secondary: "Enhances contrast for a punchier, more defined look."
    },
    {
      primary: "clean_temp",
      secondary: "Adjusts color temperature based on time of day (e.g., cooler for early morning)."
    },
  ];
  

export default function SetupsMain(){
    const [openItems, setOpenItems] = React.useState<boolean[]>(Array(2).fill(false)); 

    const toggleItem = (index: number) => {
        setOpenItems(prev => {
            const newState = [...prev];
            newState[index] = !newState[index]; 
            return newState;
        });
    };

    return(
        <Box>
            <Container sx={{ display: 'flex', flexDirection: 'column', alignItems: 'left', pt: { xs: 4, sm: 5 }, pb: { xs: 4, sm: 5 },  width: { xs: '100%', sm: '62%' } }}>
                <Stack spacing={2}>
                <ListItemButton 
                    onClick={() => toggleItem(0)}
                    sx={{
                        borderRadius: '16px', // More rounded corners
                        bgcolor: (theme) => theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : '#F5F5F5', // Light background for light theme and dark background for dark theme
                        '&:hover': {
                            bgcolor: (theme) => theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0.1)', // Slightly darker background on hover
                        },
                        transition: 'background-color 0.3s ease', // Smooth transition for background color
                    }}
                >
                        <Typography
                            component="span"
                            variant="h4"
                            sx={{ color: 'text.primary', textAlign: 'left', flexGrow: 1 }}
                        >
                            Portrait Photography
                        </Typography>
                        {openItems[0] ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={openItems[0]} timeout="auto" unmountOnExit>
                        <Typography textAlign="left" color="text.secondary" sx={{ alignSelf: 'left', fontSize: '16px' }}>
                            Creating beautiful, lifelike portraits often requires balancing background blur with accurate skin tones and exposure. These settings help enhance facial features and create a soft, pleasing background.
                        </Typography>
                        <Container sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', pb: { xs: 3, sm: 8 } }}>
                            <Stack spacing={2} useFlexGap sx={{ width: { xs: '100%', sm: '70%' } }}>
                                <StyledBox>
                                    <RecommendedParametersList rows={portrait} />
                                </StyledBox>
                            </Stack>
                        </Container>
                    </Collapse>

                    <ListItemButton 
                        onClick={() => toggleItem(1)}
                        sx={{
                            borderRadius: '16px', // More rounded corners
                            bgcolor: (theme) => theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : '#F5F5F5', // Light background for light theme and dark background for dark theme
                            '&:hover': {
                                bgcolor: (theme) => theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0.1)', // Slightly darker background on hover
                            },
                            transition: 'background-color 0.3s ease', // Smooth transition for background color
                        }}
                    >
                        <Typography
                            component="span"
                            variant="h4"
                            sx={{ color: 'text.primary', textAlign: 'left', flexGrow: 1 }}
                        >
                            Landscape Photography
                        </Typography>
                        {openItems[1] ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={openItems[1]} timeout="auto" unmountOnExit>
                        <Typography textAlign="left" color="text.secondary" sx={{ alignSelf: 'left', fontSize: '16px' }}>
                            Landscapes often contain various lighting and color levels that benefit from enhancements in dynamic range and saturation. These parameters are selected to capture as much detail as possible across both shadowed and bright areas.
                        </Typography>
                        <Container sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', pb: { xs: 3, sm: 8 } }}>
                            <Stack spacing={2} useFlexGap sx={{ width: { xs: '100%', sm: '70%' } }}>
                                <StyledBox>
                                    <RecommendedParametersList rows={landscape} />
                                </StyledBox>
                            </Stack>
                        </Container>
                    </Collapse>

                    <ListItemButton 
                        onClick={() => toggleItem(2)}
                        sx={{
                            borderRadius: '16px', // More rounded corners
                            bgcolor: (theme) => theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : '#F5F5F5', // Light background for light theme and dark background for dark theme
                            '&:hover': {
                                bgcolor: (theme) => theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0.1)', // Slightly darker background on hover
                            },
                            transition: 'background-color 0.3s ease', // Smooth transition for background color
                        }}
                    >
                        <Typography
                            component="span"
                            variant="h4"
                            sx={{ color: 'text.primary', textAlign: 'left', flexGrow: 1 }}
                        >
                            Night Photography
                        </Typography>
                        {openItems[1] ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={openItems[2]} timeout="auto" unmountOnExit>
                        <Typography textAlign="left" color="text.secondary" sx={{ alignSelf: 'left', fontSize: '16px' }}>
                            Low-light environments require a delicate balance between brightness and noise reduction. These settings optimize night shots by enhancing light sensitivity and reducing graininess.
                        </Typography>
                        <Container sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', pb: { xs: 3, sm: 8 } }}>
                            <Stack spacing={2} useFlexGap sx={{ width: { xs: '100%', sm: '70%' } }}>
                                <StyledBox>
                                    <RecommendedParametersList rows={night} />
                                </StyledBox>
                            </Stack>
                        </Container>
                    </Collapse>

                    <ListItemButton 
                        onClick={() => toggleItem(3)}
                        sx={{
                            borderRadius: '16px', // More rounded corners
                            bgcolor: (theme) => theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : '#F5F5F5', // Light background for light theme and dark background for dark theme
                            '&:hover': {
                                bgcolor: (theme) => theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0.1)', // Slightly darker background on hover
                            },
                            transition: 'background-color 0.3s ease', // Smooth transition for background color
                        }}
                    >
                        <Typography
                            component="span"
                            variant="h4"
                            sx={{ color: 'text.primary', textAlign: 'left', flexGrow: 1 }}
                        >
                            Macro Photography
                        </Typography>
                        {openItems[1] ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={openItems[3]} timeout="auto" unmountOnExit>
                        <Typography textAlign="left" color="text.secondary" sx={{ alignSelf: 'left', fontSize: '16px' }}>
                            Macro shots benefit from high sharpness and accurate color, focusing on fine details and textures of small objects. These settings help bring out intricate patterns and subtle colors.                        </Typography>
                        <Container sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', pb: { xs: 3, sm: 8 } }}>
                            <Stack spacing={2} useFlexGap sx={{ width: { xs: '100%', sm: '70%' } }}>
                                <StyledBox>
                                    <RecommendedParametersList rows={macro} />
                                </StyledBox>
                            </Stack>
                        </Container>
                    </Collapse>

                    <ListItemButton 
                        onClick={() => toggleItem(4)}
                        sx={{
                            borderRadius: '16px', // More rounded corners
                            bgcolor: (theme) => theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : '#F5F5F5', // Light background for light theme and dark background for dark theme
                            '&:hover': {
                                bgcolor: (theme) => theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0.1)', // Slightly darker background on hover
                            },
                            transition: 'background-color 0.3s ease', // Smooth transition for background color
                        }}
                    >
                        <Typography
                            component="span"
                            variant="h4"
                            sx={{ color: 'text.primary', textAlign: 'left', flexGrow: 1 }}
                        >
                            Action Photography
                        </Typography>
                        {openItems[1] ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={openItems[4]} timeout="auto" unmountOnExit>
                        <Typography textAlign="left" color="text.secondary" sx={{ alignSelf: 'left', fontSize: '16px' }}>
                            Action shots require fast adjustments to capture moving subjects with clarity and minimal blur. These settings help to stabilize and freeze motion while maintaining vivid colors.                        </Typography>
                        <Container sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', pb: { xs: 3, sm: 8 } }}>
                            <Stack spacing={2} useFlexGap sx={{ width: { xs: '100%', sm: '70%' } }}>
                                <StyledBox>
                                    <RecommendedParametersList rows={actionSettings} />
                                </StyledBox>
                            </Stack>
                        </Container>
                    </Collapse>

                    <ListItemButton 
                        onClick={() => toggleItem(5)}
                        sx={{
                            borderRadius: '16px', // More rounded corners
                            bgcolor: (theme) => theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : '#F5F5F5', // Light background for light theme and dark background for dark theme
                            '&:hover': {
                                bgcolor: (theme) => theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0.1)', // Slightly darker background on hover
                            },
                            transition: 'background-color 0.3s ease', // Smooth transition for background color
                        }}
                        >
                        <Typography
                            component="span"
                            variant="h4"
                            sx={{ color: 'text.primary', textAlign: 'left', flexGrow: 1 }}
                        >
                            Food Photography
                        </Typography>
                        {openItems[1] ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={openItems[5]} timeout="auto" unmountOnExit>
                        <Typography textAlign="left" color="text.secondary" sx={{ alignSelf: 'left', fontSize: '16px' }}>
                            Food photography focuses on vibrant colors and textures that make dishes look appetizing. These settings enhance the colors and details to create visually appealing images.                        </Typography>
                        <Container sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', pb: { xs: 3, sm: 8 } }}>
                            <Stack spacing={2} useFlexGap sx={{ width: { xs: '100%', sm: '70%' } }}>
                                <StyledBox>
                                    <RecommendedParametersList rows={food} />
                                </StyledBox>
                            </Stack>
                        </Container>
                    </Collapse>

                    <ListItemButton 
                        onClick={() => toggleItem(6)}
                        sx={{
                            borderRadius: '16px', // More rounded corners
                            bgcolor: (theme) => theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : '#F5F5F5', // Light background for light theme and dark background for dark theme
                            '&:hover': {
                                bgcolor: (theme) => theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0.1)', // Slightly darker background on hover
                            },
                            transition: 'background-color 0.3s ease', // Smooth transition for background color
                        }}
                    >
                        <Typography
                            component="span"
                            variant="h4"
                            sx={{ color: 'text.primary', textAlign: 'left', flexGrow: 1 }}
                        >
                            Architecture Photography
                        </Typography>
                        {openItems[1] ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={openItems[6]} timeout="auto" unmountOnExit>
                        <Typography textAlign="left" color="text.secondary" sx={{ alignSelf: 'left', fontSize: '16px' }}>
                            Landscapes often contain various lighting and color levels that benefit from enhancements in dynamic range and saturation. These parameters are selected to capture as much detail as possible across both shadowed and bright areas.
                        </Typography>
                        <Container sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', pb: { xs: 3, sm: 8 } }}>
                            <Stack spacing={2} useFlexGap sx={{ width: { xs: '100%', sm: '70%' } }}>
                                <StyledBox>
                                    <RecommendedParametersList rows={architecture} />
                                </StyledBox>
                            </Stack>
                        </Container>
                    </Collapse>

                    <ListItemButton 
                        onClick={() => toggleItem(7)}
                        sx={{
                            borderRadius: '16px', // More rounded corners
                            bgcolor: (theme) => theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.1)' : '#F5F5F5', // Light background for light theme and dark background for dark theme
                            '&:hover': {
                                bgcolor: (theme) => theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0.1)', // Slightly darker background on hover
                            },
                            transition: 'background-color 0.3s ease', // Smooth transition for background color
                        }}
                    >
                        <Typography
                            component="span"
                            variant="h4"
                            sx={{ color: 'text.primary', textAlign: 'left', flexGrow: 1 }}
                        >
                            Street Photography
                        </Typography>
                        {openItems[1] ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={openItems[7]} timeout="auto" unmountOnExit>
                        <Typography textAlign="left" color="text.secondary" sx={{ alignSelf: 'left', fontSize: '16px' }}>
                            Street photography benefits from quick adjustments for changing light conditions, with a focus on capturing candid moments with natural tones and subtle textures.                        </Typography>
                        <Container sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', pb: { xs: 3, sm: 8 } }}>
                            <Stack spacing={2} useFlexGap sx={{ width: { xs: '100%', sm: '70%' } }}>
                                <StyledBox>
                                    <RecommendedParametersList rows={street} />
                                </StyledBox>
                            </Stack>
                        </Container>
                    </Collapse>
                </Stack>
            </Container>
        </Box>
    );
}