export default function convertFullDate(inputDate: string): string {
    const date = new Date(inputDate);
    const day = date.getDate();
    const monthNames = [
        'January', 'February', 'March', 'April',
        'May', 'June', 'July', 'August',
        'September', 'October', 'November', 'December'
    ];
    const monthName = monthNames[date.getMonth()];
    const year = date.getFullYear();
    
    // Function to get ordinal suffix for day
    const getOrdinalSuffix = (day: number): string => {
        if (day === 1 || day === 21 || day === 31) {
            return 'st';
        } else if (day === 2 || day === 22) {
            return 'nd';
        } else if (day === 3 || day === 23) {
            return 'rd';
        } else {
            return 'th';
        }
    };

    const ordinalSuffix = getOrdinalSuffix(day);
    
    // Format the date with ordinal suffix
    const formattedDate = `${day}${ordinalSuffix} of ${monthName} ${year}`;
    return formattedDate;
}