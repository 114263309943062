import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Stack } from '@mui/material';

export default function FAQMain() {
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <Container
        sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            pt: { xs: 14, sm: 20 },
            pb: { xs: 3, sm: 8 },
            borderBottom: '2px solid',
            borderColor: 'divider'
        }}
    >
        <Stack spacing={2} useFlexGap sx={{ width: { xs: '100%', sm: '70%' } }}>
          <Typography
            variant="h1"
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              alignSelf: 'center',
              textAlign: 'center',
              fontSize: 'clamp(3.5rem, 10vw, 4rem)',
            }}
          >
            FAQ and&nbsp;
            <Typography
              component="span"
              variant="h1"
              sx={{
                fontSize: 'clamp(3rem, 10vw, 4rem)',
                color: (theme) =>
                  theme.palette.mode === 'light' ? 'primary.main' : 'primary.light',
              }}
            >
              Troubleshooting
            </Typography>
          </Typography>
        </Stack>
        <Box sx={{ width: '100%', pt: { xs: 7, sm: 10 } }}>
            <Accordion
            expanded={expanded === 'panel1'}
            onChange={handleChange('panel1')}
            >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1d-content"
                id="panel1d-header"
            >
                <Typography component="h3" variant="subtitle2">
                What is GCAM, and how does it enhance mobile photography?
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography
                variant="body2"
                gutterBottom
                sx={{ maxWidth: { sm: '100%', md: '90%' }, textAlign: 'left' }}
                >
                    GCAM, short for Google Camera, is a camera application developed by Google with advanced computational photography features. It’s known for producing sharp, detailed images and has modes like HDR+, Night Sight, and Portrait, which improve low-light, dynamic range, and depth effects. These features often provide better photo quality compared to stock camera apps.
                </Typography>
            </AccordionDetails>
            </Accordion>
            <Accordion
            expanded={expanded === 'panel2'}
            onChange={handleChange('panel2')}
            >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2d-content"
                id="panel2d-header"
            >
                <Typography component="h3" variant="subtitle2">
                What are the benefits of using GCam over my stock camera app?
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography
                variant="body2"
                gutterBottom
                sx={{ maxWidth: { sm: '100%', md: '90%' }, textAlign: 'left' }}
                >
                GCam provides superior image processing, particularly in features like HDR+, Night Sight, and Super Res Zoom. These features help deliver better dynamic range, clearer low-light images, and improved zoom quality compared to most stock camera apps. Additionally, GCam often handles colors more accurately and reduces noise, especially in challenging conditions like low light.
                </Typography>
            </AccordionDetails>
            </Accordion>
            <Accordion
            expanded={expanded === 'panel3'}
            onChange={handleChange('panel3')}
            >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3d-content"
                id="panel3d-header"
            >
                <Typography component="h3" variant="subtitle2">
                Is GCam compatible with my phone?
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography
                variant="body2"
                gutterBottom
                sx={{ maxWidth: { sm: '100%', md: '90%', textAlign: 'left' } }}
                >
                GCam is compatible with many Android devices, but compatibility depends on whether your phone has Camera2 API enabled, which is essential for GCam to function. Popular devices from brands like Xiaomi, OnePlus, Samsung, Google Pixel, Realme, and others often have compatible versions. To check compatibility, you can use apps like Camera2 API Probe to see if your device supports the required features.
                </Typography>
            </AccordionDetails>
            </Accordion>
            <Accordion
            expanded={expanded === 'panel4'}
            onChange={handleChange('panel4')}
            >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel4d-content"
                id="panel4d-header"
            >
                <Typography component="h3" variant="subtitle2">
                How do I install GCam on my Android device?
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
            <Typography variant="body2"
              gutterBottom
              sx={{ maxWidth: { sm: '100%', md: '90%', textAlign: 'left' } }}>
            If you're experiencing issues with installation or setup, please refer to our  
            <Link href="/installation-bsg" underline="hover" sx={{ fontWeight: 'bold', color: 'primary.main' }}>
               {" "} Installation Guide {" "}
            </Link> 
             for step-by-step instructions and troubleshooting tips.
        </Typography>
            </AccordionDetails>
            </Accordion>
            <Accordion
            expanded={expanded === 'panel5'}
            onChange={handleChange('panel5')}
            >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel5d-content"
                id="panel5d-header"
            >
                <Typography component="h3" variant="subtitle2">
                How do I update GCam to the latest version?
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography
                variant="body2"
                gutterBottom
                sx={{ maxWidth: { sm: '100%', md: '90%', textAlign: 'left' } }}
                >
                Download the updated APK from our official page. Simply install the APK as you would any app, and it will overwrite the previous version without deleting your settings.
                </Typography>
            </AccordionDetails>
            </Accordion>
            <Accordion
            expanded={expanded === 'panel6'}
            onChange={handleChange('panel6')}
            >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel6d-content"
                id="panel6d-header"
            >
                <Typography component="h3" variant="subtitle2">
                How can I join the GCam community for updates and feedback?
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography
                variant="body2"
                gutterBottom
                sx={{ maxWidth: { sm: '100%', md: '90%', textAlign:'left' } }}
                >
                <Typography variant="body2" gutterBottom>
    You can join the GCam community by participating in our official <a href="https://t.me/Channel_MGC_BSG" target="_blank" rel="noopener noreferrer">Telegram channel</a> and <a href="https://t.me/PublicGroup_MGC_BSG" target="_blank" rel="noopener noreferrer">chat</a> for real-time updates and discussions. Here, you can get the latest GCam versions, tips from other users, and direct feedback on any issues you might face.
    </Typography>
                </Typography>
            </AccordionDetails>
            </Accordion>
            <Accordion
    expanded={expanded === 'panel7'}
    onChange={handleChange('panel7')}
>
    <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel7d-content"
        id="panel7d-header"
    >
        <Typography component="h3" variant="subtitle2">
            Why do my photos sometimes appear too dark or too bright?
        </Typography>
    </AccordionSummary>
    <AccordionDetails>
        <Typography variant="body2" gutterBottom sx={{ maxWidth: { sm: '100%', md: '90%', textAlign:'left' } }}>
            This can happen if exposure settings aren’t correctly adjusted. Try using the “evc_override” parameter to increase or decrease the exposure compensation based on lighting. Also, check if HDR+ is enabled, as it can balance highlights and shadows automatically for better exposure.
        </Typography>
    </AccordionDetails>
            </Accordion>
            <Accordion
                expanded={expanded === 'panel8'}
                onChange={handleChange('panel8')}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel8d-content"
                    id="panel8d-header"
                >
                    <Typography component="h3" variant="subtitle2">
                        What can I do if my images look overly sharp or too soft?
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography variant="body2" gutterBottom sx={{ maxWidth: { sm: '100%', md: '90%', textAlign:'left' } }}>
                        Adjust the “sharpen_strength_factor” parameter to control the level of sharpness applied. If the images are too sharp, reduce the setting slightly. Conversely, if they appear too soft, increase it. Adjustments will vary depending on the scene and your aesthetic preferences.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion
                expanded={expanded === 'panel9'}
                onChange={handleChange('panel9')}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel9d-content"
                    id="panel9d-header"
                >
                    <Typography component="h3" variant="subtitle2">
                        How can I fix alignment issues in burst mode?
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography variant="body2" gutterBottom sx={{ maxWidth: { sm: '100%', md: '90%', textAlign:'left' } }}>
                        Misalignment can occur due to hand movement. If possible, use a tripod or stabilizer to reduce movement. For minor misalignments, parameters like “sabre_disable_rejection” may help by reducing noise during the alignment process.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion
                expanded={expanded === 'panel10'}
                onChange={handleChange('panel10')}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel10d-content"
                    id="panel10d-header"
                >
                    <Typography component="h3" variant="subtitle2">
                        Why do I see extra noise in my low-light photos, and how can I reduce it?
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography variant="body2" gutterBottom sx={{ maxWidth: { sm: '100%', md: '90%', textAlign:'left' } }}>
                        Low-light noise often appears when the camera’s sensitivity is high. Use “luma_denoise_strength_factor” and “chroma_denoise_strength_factor” to manage noise reduction levels. Increasing these settings can help reduce noise, but avoid overdoing it as it can blur details.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion
                expanded={expanded === 'panel11'}
                onChange={handleChange('panel11')}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel11d-content"
                    id="panel11d-header"
                >
                    <Typography component="h3" variant="subtitle2">
                        What are the recommended settings to minimize motion blur?
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography variant="body2" gutterBottom sx={{ maxWidth: { sm: '100%', md: '90%', textAlign:'left' } }}>
                        To reduce motion blur, enable a faster shutter speed if possible and activate image stabilization in the app settings. You can also adjust “evc_override” for better exposure balance. For fast-moving objects, consider using modes that prioritize faster processing to capture clear shots.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion
                expanded={expanded === 'panel12'}
                onChange={handleChange('panel12')}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel12d-content"
                    id="panel12d-header"
                >
                    <Typography component="h3" variant="subtitle2">
                        How can I reset all parameters to default if I’ve made too many adjustments?
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography variant="body2" gutterBottom sx={{ maxWidth: { sm: '100%', md: '90%', textAlign:'left' } }}>
                        In GCAM settings, there’s typically an option to reset parameters to default. Look for a “reset settings” option under the Advanced or Developer Options section. Alternatively, reinstalling the app can restore it to the original settings.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion
                expanded={expanded === 'panel13'}
                onChange={handleChange('panel13')}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel13d-content"
                    id="panel13d-header"
                >
                    <Typography component="h3" variant="subtitle2">
                        What should I do if GCAM crashes or doesn’t save images properly?
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography variant="body2" gutterBottom sx={{ maxWidth: { sm: '100%', md: '90%', textAlign:'left' } }}>
                        GCAM crashes may occur if settings are incompatible with your device. Reset any recently changed parameters and restart the app. If the issue persists, try using a different GCAM version or modded APK. Clearing the app cache may also help improve stability.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion
                expanded={expanded === 'panel14'}
                onChange={handleChange('panel14')}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel14d-content"
                    id="panel14d-header"
                >
                    <Typography component="h3" variant="subtitle2">
                        How can I improve my portrait mode photos using specific parameters?
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography variant="body2" gutterBottom sx={{ maxWidth: { sm: '100%', md: '90%', textAlign:'left' } }}>
                        Portrait mode relies on accurate depth detection, which can be adjusted with settings like “portrait_blur_strength” for stronger or softer background blur. For clearer details, adjust sharpness parameters slightly and make sure HDR+ is enabled, as it can improve edge clarity and dynamic range.
                    </Typography>
                </AccordionDetails>
            </Accordion>
      </Box>
    </Container>
  );
}