import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/system';

// Импортируем локальные изображения
import xiami from '../icons/xiaomi.svg';
import xiamilight from '../icons/xiaomi-light.svg';
import oneplus from '../icons/oneplus.svg';
import oneplusdark from '../icons/oneplus-dark.svg';
import samsung from '../icons/samsung.svg';
import samsunglight from '../icons/samsung-light.svg';
import motorola from '../icons/motorola.svg';
import asus from '../icons/asus.svg';
import oppo from '../icons/oppo.svg';
import vivo from '../icons/vivo.svg';
import vivolight from '../icons/vivo-light.svg';

// Логотипы для светлой и тёмной темы
const whiteLogos = [
  xiamilight,
  oneplus,
  samsunglight,
  motorola,
  asus,
  vivolight
];

const darkLogos = [
  xiami,
  oneplusdark,
  samsung,
  motorola,
  asus,
  vivo
];

const logoStyle = {
  width: '70px',
  height: '60px',
  margin: '0 32px',
  opacity: 0.7,
};

export default function LogoCollection() {
  const theme = useTheme();
  const logos = theme.palette.mode === 'light' ? darkLogos : whiteLogos;

  return (
    <Box id="logoCollection" sx={{ py: 4 }}>
      <Typography
        component="p"
        variant="subtitle2"
        align="center"
        sx={{ color: 'text.secondary' }}
      >
        Google Camera mod supports a wide variety of Android smartphones, including popular models and more
      </Typography>
      <Grid container sx={{ justifyContent: 'center', mt: 0.5, opacity: 0.6 }}>
        {logos.map((logo, index) => (
          <Grid item key={index}>
            <img
              src={logo}
              alt={`Fake company number ${index + 1}`}
              style={logoStyle}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
