import * as React from 'react';
import { Breadcrumbs, Card, CardActions, CardContent, CircularProgress, alpha } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import VersionsCard from './VersionsCard';
import { useEffect, useState } from 'react';
import Footer from '../Footer/Footer';

export default function VersionsComponent() {
  let main;
  main = (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        pt: { xs: 14, sm: 20 },
        pb: { xs: 8, sm: 12 },
      }}
    >
    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-start', paddingBottom: '4%' }}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" color="inherit" href="/">
          Home
        </Link>
        <Typography color="text.primary">All BSG versions</Typography>
      </Breadcrumbs>
    </Box>
       <VersionsCard/>
    </Container>
  );

  
  return (
    <Box
      id="hero"
      sx={(theme) => ({
        width: '100%',
        backgroundImage:
          theme.palette.mode === 'light'
            ? 'linear-gradient(180deg, #CEE5FD, #FFF)'
            : `linear-gradient(#02294F, ${alpha('#090E10', 0.0)})`,
        backgroundSize: '100% 20%',
        backgroundRepeat: 'no-repeat',
      })}
    >
      {main}
      <Footer/>
    </Box>
  );
}