import { Container, Stack, Typography } from "@mui/material";

export default function ParametersIntroduction() {
    return(
        <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'left',
          pt: { xs: 14, sm: 20 },
          pb: { xs: 3, sm: 8 },
          borderBottom: '2px solid',
          borderColor: 'divider'
        }}
      >
        <Stack spacing={2} useFlexGap sx={{ width: { xs: '100%', sm: '70%' } }}>
          <Typography
            variant="h1"
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              alignSelf: 'left',
              textAlign: 'left',
              fontSize: 'clamp(3.5rem, 10vw, 4rem)',
            }}
          >
            Parameters&nbsp;
            <Typography
              component="span"
              variant="h1"
              sx={{
                fontSize: 'clamp(3rem, 10vw, 4rem)',
                color: (theme) =>
                  theme.palette.mode === 'light' ? 'primary.main' : 'primary.light',
              }}
            >
              Guide
            </Typography>
          </Typography>
          <Typography
            textAlign="left"
            color="text.secondary"
            sx={{ alignSelf: 'left', fontSize: '16px' }}
          >
            In this section, we will explain image processing parameters and their importance in photography. Parameters are tools that allow users to adapt their photos to individual preferences, improving image quality and achieving desired effects.
          </Typography>
          <Typography
            textAlign="left"
            color="text.secondary"
            sx={{ alignSelf: 'left',  fontSize: '16px' }}
          >
            Each parameter is designed to serve a specific function, and proper use of these parameters can significantly enhance photo quality. This page will help you familiarize yourself with the capabilities of each parameter and master the essential settings.
          </Typography>
          <Typography
            textAlign="left"
            color="text.secondary"
            sx={{ alignSelf: 'left',  fontSize: '16px' }}
          >
            We recommend using the sections and table of contents for quick information retrieval. If you are looking for a specific parameter, you can use the search function on the page.
          </Typography>
        </Stack>
        </Container>
    );
}