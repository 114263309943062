import { Accordion, AccordionDetails, AccordionSummary, alpha, Box, Breadcrumbs, Container, Link, Stack, Typography } from "@mui/material";
import CommonPage from "../Common/CommonPage";
import Footer from "../Footer/Footer";
import { useState } from "react";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React from "react";
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';



const rows = [
    { name: 'clean_shot_params', description: 'When set to true, it forces all associated clean parameters—clean_crop, clean_touch, and clean_evc—to true, ensuring a comprehensive reset of processing settings to eliminate artifacts from previous shots.' },
    { name: 'clean_crop', description: 'During the reprocessing of images, this parameter resets any cropping that may have been applied previously. It disables digital zoom features and any aspect ratio modifications, such as cropping to a 16:9 format, ensuring the target width and height are set to the active area of the image.' },
    { name: 'clean_touch', description: 'When reprocessing images, this parameter resets the touch-to-expose settings, effectively reverting any touch exposure adjustments made previously to ensure accurate exposure settings in the new processing.' },
    { name: 'clean_evc', description: 'This parameter resets the exposure compensation (EVC) settings when reprocessing images, allowing for a fresh start in exposure adjustments without retaining previous values.' },
    { name: 'ux_mode_override', description: 'When reprocessing shots, this parameter overrides the specified AeShotParams user experience (UX) mode with the provided option, which can be one of "hdr_plus_on", "hdr_plus_enhanced", "night_sight", "portrait", "third_party", or "motion", enabling the user to select a different processing mode.' },
    { name: 'evc_override', description: 'This parameter allows for the overriding of the exposure compensation value (shot_params.ae.exposure_compe) when reprocessing shots, providing greater control over exposure settings during the image processing workflow.' },
    { name: 'touch_override', description: 'During reprocessing, this parameter overrides the areas used for weighted metering in exposure calculations. It accepts a specified rectangle and incorporates a full-field-of-view (FOV) rectangle with a weight of 1, requiring the input string to be formatted as --touch_override=x0 x1 y0 y1 weight, enabling targeted adjustments.' },
    { name: 'enable_ml_luma_denoise_override', description: 'Controls the operation of the machine learning (ML) Luma denoiser. A value less than 0 has no effect; a value of 0 disables the denoiser entirely; a value greater than 0 ensures that the denoiser is always attempted during processing for improved image quality.' },
    { name: 'disable_hot_pixel_detection', description: 'Disables the detection mechanism for hot pixels, which can help streamline processing but may result in visible artifacts if hot pixels are present in the image.' },
    { name: 'disable_row_artifact_suppression', description: 'Turns off the suppression of row noise and pattern artifacts during processing, potentially allowing these artifacts to be visible in the output image.' },
    { name: 'max_black_level_offset_override', description: 'This parameter allows for the manual override of the max_black_level_offset tuning setting, which is crucial for controlling the estimation of black levels in the final image, allowing for adjustments to enhance the dynamic range.' },
    { name: 'wb_temperature_adjust', description: 'Shifts the perceived color temperature of the final image by a specified amount in Kelvin. This adjustment only affects the output if the --recompute_awb option is also enabled, allowing for more precise white balance corrections.' },
    { name: 'rerun_portrait_segment', description: 'Forces the re-generation of portrait segmentation masks using the postview image, even if these masks were previously saved with the burst capture. This option is specifically available on gLinux platforms.' },
    { name: 'enable_portrait_brightening', description: 'Activates portrait brightening effects when set to true, or disables it when set to false. However, note that portrait brightening will only take effect in certain user experience (UX) modes, limiting its application under specific conditions.' },
    { name: 'debug_save_postview', description: 'Saves the result of the postview processing stage, allowing for later review and analysis of the output image before any further adjustments or changes are made.' },
    { name: 'debug_save_merged_dng', description: 'Saves the merged output as a DNG file. It is important to note that this DNG may not match the original DNG provided to the DNG callback, as it could contain additional processing.' },
    { name: 'disable_dng_thumbnail', description: 'Prevents the generation of a thumbnail for the DNG file, which can help reduce processing time and file size when thumbnails are not necessary.' },
    { name: 'debug_save_finished_pre_resample', description: 'Saves the finished output result before any resampling or rotation has been applied, providing a snapshot of the image at that stage of processing for debugging purposes.' },
    { name: 'debug_save_final_output', description: 'Saves the final output result of the processing pipeline, capturing the image just before any rotation adjustments are applied, which is useful for verification and analysis.' },
    { name: 'strip_xmp_metadata', description: 'Removes XMP metadata from the output when writing a JPG or PNG file. This functionality is particularly useful for generating consistent golden samples and testing outputs to ensure bit-identical results across multiple runs.' },
    { name: 'draw_face_rects', description: 'Incorporates rectangles around detected faces into the final image output, providing visual cues for facial detection and recognition processes.' },
    { name: 'draw_touch_rects', description: 'Adds rectangles to the final image to indicate areas where touch events have occurred, aiding in visual debugging of touch interactions during image capture.' },
    { name: 'draw_face_landmarks', description: 'Overlays landmarks on detected facial features within the final image, which can enhance facial recognition and analysis applications.' },
    { name: 'draw_border_for_deblur_badge', description: 'If the image qualifies as a candidate for the deblur badge, this parameter draws a border around the outside of the final image, visually indicating its potential for further enhancement.' },
    { name: 'remosaic_before_finish', description: 'Destructively remosaics the output from the SABRE processing stage before it is finalized, used primarily for experimental purposes to evaluate the effects of remosaicking.' },
    { name: 'quad_bayer_remosaic', description: 'Specifies whether to use a quad Bayer pattern for remosaicking instead of a standard Bayer pattern. This option is particularly relevant when generating merged DNG outputs as indicated by the ShotParams::remosaic_merged_output setting.' },
    { name: 'disable_align', description: 'Disables the alignment process during burst merging, resulting in alignment displacements being set to zero. This can simplify processing but may lead to less optimal results in image quality.' },
    { name: 'debug_save_alignment', description: 'Saves intermediate results from burst alignment processing, enabling analysis of alignment adjustments and helping to diagnose issues related to image merging.' },
    { name: 'disable_makernote_details', description: 'Prevents the generation of specific components within the makernote section of the output file, which can help to reduce processing latency and improve efficiency during file generation.' },
    { name: 'fit_dng_gain_table', description: 'Adjusts the DNG ProfileGainTableMap and embeds it into the final merged raw DNG file. This is essential for ensuring accurate gain mapping in the processed output.' },
    { name: 'debug_save_dng_gain_table', description: 'Saves the gain table as a numpy array, allowing for in-depth analysis and debugging of gain values applied during the DNG conversion process.' },
    { name: 'debug_save_finished_resampled_for_dng', description: 'Saves the resampled final image to be used in grid fitting, enhancing accuracy in grid alignment during processing.' },
    { name: 'pecan_min_overall_zoom_level', description: 'Sets the minimum zoom level for activating Pecan in normal mode. If this value is >=0, Pecan operates normally at or above this zoom level; if <0, Pecan remains inactive in normal mode.' },
    { name: 'walnut_override', description: 'Overrides Walnut processing behavior: set to 0 to prevent Walnut from running, to >0 to force it to run, and <0 to use the default setting.' },
    { name: 'unlimited_zsl_payload_frames', description: 'Controls whether to allow an unlimited number of frames in the Zero Shutter Lag (ZSL) payload, enabling flexibility in frame capture during ZSL operations.' },
    { name: 'bracketed_sharpness_threshold', description: 'Drops any bracketed frame whose sharpness score is below the specified factor of the base frame’s sharpness, ensuring only high-quality frames are retained in bracketed shots.' },
    { name: 'night_sight_sharpness_delta_factor', description: 'In Zero Shutter Lag (ZSL) and Post-Shutter Lag (PSL) Night Sight shots, discards short exposure PSL frames with sharpness scores significantly differing (based on the factor provided) from the base frame’s sharpness.' },
    { name: 'max_bracketed_tet_ratio', description: 'Sets the maximum Total Exposure Time (TET) ratio allowed between any bracketed frame and the base frame. If this limit is exceeded, all bracketed frames are discarded to maintain exposure consistency.' },
    { name: 'num_base_frame_candidates_override', description: 'Overrides the default number of base frame candidates if set to a value greater than zero, allowing custom selection for base frame processing.' },
    { name: 'merge_max_frame_count', description: 'Limits the maximum number of frames to be merged if a positive number is set, which helps optimize processing resources by capping the frame count.' },
    { name: 'raisr2022_polysharp_max_blur', description: 'Defines the maximum allowable blur level for Polysharp sharpening, with valid values ranging from 0 to 4.0. Setting a value below 0.38 (e.g., 0.0) disables Polysharp sharpening.' },
    { name: 'ccm_override', description: 'Overrides the default Color Correction Matrix (CCM) applied to map sensor RGB data to the output color space. Options include "none", "identity", "red", "green", and "blue".' },
    { name: 'sharpen_strength_factor', description: 'Applies additional sharpening to the image, enhancing detail based on the specified factor.' },
    { name: 'override_sharpen_polysharp_sigma', description: 'Overrides the default sharpening by approximating Polysharp with a specified sigma value if greater than zero.' },
    { name: 'override_sharpen_polysharp_alpha', description: 'Sets the alpha value for Polysharp approximation when "override_sharpen_polysharp_sigma" is used, controlling the sharpening intensity.' },
    { name: 'override_sharpen_polysharp_d', description: 'Defines the "d" parameter for Polysharp approximation when using "override_sharpen_polysharp_sigma", affecting the sharpening algorithm.' },
    { name: 'override_sharpen_max_detail', description: 'Controls the maximum detail level for sharpen curves when "override_sharpen_polysharp_sigma" is applied, setting an upper limit for sharpening detail.' },
    { name: 'luma_denoise_strength_factor', description: 'Applies additional luma (brightness) denoising, specified as a list of factors for different frequencies. Missing values default to the last specified factor.' },
    { name: 'luma_denoise_outlier_threshold_factor', description: 'Adjusts the outlier threshold for luma denoising by a scaling factor, applied per frequency. Missing values default to the last specified factor.' },
    { name: 'luma_denoise_revert_factor', description: 'Specifies the degree of reversion to the original image after luma denoising, as a list of factors for each frequency. Missing values default to the last specified factor.' },
    { name: 'chroma_denoise_strength_factor', description: 'Applies additional chroma (color) denoising, allowing a list of per-frequency factors for finer control over denoising at different frequencies. Missing values default to the last specified factor.' },
    { name: 'dehaze_strength_factor', description: 'Determines the level of dehazing applied to the image. Setting to 0 disables dehazing, and can be combined with --dynamic_highlight_adjustment=0 for further control.' },
    { name: 'rgb_lut_strength_factor', description: 'Controls the strength of the 3D Look-Up Table (LUT) applied for color transformation. A value of 0 means no LUT is applied (identity LUT).' },
    { name: 'log2_downsample_factor_override', description: 'Overrides the downsampling factor in finishing if set to a non-negative value, controlling the image’s resolution reduction rate.' },
    { name: 'disable_cpu_fallback', description: 'Prevents the system from automatically switching to CPU processing if offload processing (e.g., GPU) fails, enforcing strict offload execution.' },
    { name: 'disable_sky_denoise', description: 'Determines if sky denoising is applied in Night mode. Only relevant if sky optimization is enabled.' },
    { name: 'disable_per_channel_warp', description: 'Disables per-channel warping used for correcting lateral chromatic aberration (LCA) across different color channels.' },
    { name: 'rgb_lut_override', description: 'Specifies an RGB Look-Up Table (LUT) file to override the default color adjustment, typically provided in HaldCLUT format.' },
    { name: 'fix_stray_light', description: 'Attempts to detect and reduce stray light artifacts before Local Tone Mapping (LTM), improving image clarity in challenging lighting.' },
    { name: 'per_channel_warp_at_full_res', description: 'Applies per-channel warping for LCA correction at full resolution if enabled, but may be overridden by setting --disable_per_channel_warp.' },
    { name: 'apply_highlight_recovery_override', description: 'Forces highlight recovery based on the value: >0 enables, 0 disables, and <0 leaves the default behavior.' },
    { name: 'apply_demoire_override', description: 'Controls demoire processing: >0 enables, 0 disables, and <0 retains default settings.' },
    { name: 'debug_save_preview_raw', description: 'Saves the downsampled raw image that is used to generate the preview, allowing inspection of the initial preview data.' },
    { name: 'apply_sgm_in_raw_to_yuv', description: 'Applies the spatial gain map during the RawToYuv conversion stage instead of applying it later, optimizing low-frequency processing stages.' },
    { name: 'apply_sgm_spatially_varying', description: 'Divides the spatial gain map in RawToYuv into two spatially varying parts to minimize clipping, enhancing image quality in low light areas.' },
    { name: 'apply_sgm_factorize_p', description: 'Specifies the power used to separate the spatial gain map into two components, with one part applied in RawToYuv to reduce clipping effects.' },
    { name: 'apply_sgm_spatially_varying_filter', description: 'Applies a smoothing filter to the spatially varying gain map, balancing transitions in illumination across the image.' },
    { name: 'global_shadow_boost', description: 'When enabled, boosts shadows globally and skips Local Tone Mapping (LTM), focusing on shadow enhancement.' },
    { name: 'ltm', description: 'Applies Local Tone Mapping (LTM) if true; if false, only global exposure based on short exposure time (short_tet) is applied.' },
    { name: 'dehaze', description: 'Applies a dehazing effect to reduce fog or haze in the image, improving clarity and contrast.' },
    { name: 'dynamic_highlight_adjustment', description: 'Enables dynamic adjustment of highlights during the dehaze process, optimizing exposure in bright areas.' },
    { name: 'debug_save_hdr', description: 'Saves intermediate results from HDR processing, providing a snapshot of HDR adjustments for debugging purposes.' },
    { name: 'debug_save_optimize_sky', description: 'If set to true, debug images for sky optimization are saved, allowing analysis of sky-specific adjustments.' },
    { name: 'sky_segmenter_name', description: 'Specifies the sky segmenter to use. An empty string defaults to the segmenter pre-defined in SkySegmenterManager.' },
    { name: 'ml_denoise_model_path', description: 'Defines the file path for the Pecan (Machine Learning Denoise) model in TensorFlow Lite (tflite) format, overriding any embedded model path.' },
    { name: 'ml_denoise_apply_srgb_transfer_function', description: 'Applies an sRGB transfer function to model inputs; deprecated in favor of metadata within the tflite file itself.' },
    { name: 'ml_denoise_strength', description: 'Determines the intensity of ML-based denoising, with 1 as the default and 0 for no denoising effect.' },
    { name: 'ml_denoise_save_debug', description: 'Enables saving of debug images in the ML denoiser, assisting in analyzing denoising performance.' },
    { name: 'ml_denoise_save_debug_gain', description: 'Applies an additional gain to debug images saved during ML denoising, enhancing visibility of debug details.' },
    { name: 'ml_denoise_use_darwinn', description: 'Attempts to run the ML denoiser on the DarwiNN TPU, if available, for accelerated processing.' },
    { name: 'ml_denoise_low_snr_noise_boost', description: 'Boosts the noise map when Signal-to-Noise Ratio (SNR) is below a threshold, scaling by (1 + ml_denoise_low_snr_noise_boost).' },
    { name: 'ml_denoise_revert_strength', description: 'Controls the amount of noise re-added to the image post-denoising for a natural texture balance.' },
    { name: 'debug_save_process_raw', description: 'Saves intermediate results from the ProcessRaw stage, providing a checkpoint of processing states for review.' },
    { name: 'debug_print_process_raw_hashes', description: 'Prints hashes of intermediate results in ProcessRaw, useful for tracking data integrity and debugging changes.' },
    { name: 'debug_save_noise_std_dev', description: 'Saves the noise standard deviation map as a numpy checkpoint, enabling detailed analysis of noise distribution (may be slow).' },
    { name: 'wiener_merge_temporal_strength_override', description: 'Overrides the temporal strength parameter used in Wiener merge, adjusting temporal denoising intensity.' },
    { name: 'wiener_merge_spatial_strength_override', description: 'Overrides the spatial strength parameter in Wiener merge, fine-tuning the spatial denoising level.' },
    { name: 'debug_print_merge_raw_hashes', description: 'Prints hashes of MergeRaw intermediate results, facilitating verification and troubleshooting of merge steps.' },
    { name: 'sabre_log_coverage_info', description: 'Logs detailed coverage information per channel as well as aggregate coverage, supporting performance analysis.' },
    { name: 'sabre_save_guides_gain', description: 'Saves half-resolution guide images for SABRE processing if >0. The value controls the gain applied to the saved guide images.' },
    { name: 'sabre_save_rejection_masks', description: 'Saves SABRE rejection masks for debugging or analysis purposes.' },
    { name: 'sabre_disable_rejection', description: 'Disables rejection in SABRE processing, allowing all frames to pass without rejection filters.' },
    { name: 'black_level_std', description: 'Accounts for black level variability in base frame variance calculation for rejection purposes. This feature is experimental.' },
    { name: 'save_rejection_masks', description: 'Saves low-resolution rejection masks for each frame, providing insights into frame rejection criteria.' },
    { name: 'merge_debug_frame_index', description: 'If non-negative, renders only a single frame (after alignment) with the specified index for targeted debugging.' },
    { name: 'save_denoise_variance_multiplier_map', description: 'Saves the combined denoise variance multiplier map as a 16-bit grayscale PNG for analysis.' },
    { name: 'motion_scale_factor', description: 'Sets the motion scale factor, influencing how frame weights adjust in response to motion.' },
    { name: 'maximum_weight_clamp', description: 'Defines the maximum permissible weight for any frame or local region, with excess values clamped to this limit.' },
    { name: 'debug_print_spatial_merge_hashes', description: 'Prints hashes of intermediate results from SpatialMerge to aid in debugging or consistency checks.' },
    { name: 'rejected_denoise_multiplier_override', description: 'Overrides the rejected_denoise_multiplier setting if the value is non-negative; otherwise, the tuning value is used.' },
    { name: 'disable_block_detector', description: 'Disables the block detector, which could impact processing of certain image block artifacts.' },
    { name: 'disable_kernel_size_boost', description: 'Prevents any boost to kernel size, potentially affecting fine details in denoising operations.' },
    { name: 'debug_alignment_corruption', description: 'Specifies the likelihood of introducing artificial alignment corruption to whole alignment tiles for testing purposes.' },
    { name: 'debug_alignment_random_error', description: 'Sets the standard deviation for introducing random small alignment errors, helping to test alignment tolerance.' },
    { name: 'sabre_override_max_scale_factor', description: 'Overrides the maximum scale factor for SABRE if set below 1.0f, keeping the default otherwise.' },
    { name: 'sabre_luma_denoise_tuning', description: 'Specifies SABRE luma denoise tuning parameters as text proto content or a path to a proto file.' },
    { name: 'merge_override_kernel_size', description: 'Overrides the base kernel size for spatial merging if greater than 0.0f, influencing merge characteristics.' },
    { name: 'debug_show_tile_bounds', description: 'Displays tile boundaries in alignment debug outputs, helping visualize alignment segmentation.' },
    { name: 'debug_save_alignment_gain', description: 'Applies an additional gain to alignment frames when saved, allowing better visibility of debug data.' },
    { name: 'tb_motion_metering', description: 'Enables motion metering to determine if temporal binning should be applied based on detected motion levels.' },
    { name: 'workaround_bad_ae_bursts', description: 'Adjusts tuning to accommodate bad AE bursts with incorrect metadata, especially for downsampled DNG files.' },
    { name: 'disable_noise_model_override', description: 'Prevents any noise model override from tuning, relying on default noise settings.' },
    { name: 'fixed_black_level', description: 'Sets a fixed black level for all channels if the value is non-negative, standardizing baseline levels.' },
    { name: 'use_override_black_levels', description: 'Applies overridden black levels across the HDR+ pipeline, not limited to spatial merging and sharpness metrics.' },
    { name: 'luma_denoise_tuning', description: 'Configures luma denoise tuning parameters through text proto content or a specified file path.' },
    { name: 'chroma_denoise_tuning', description: 'Defines chroma denoise tuning parameters as text proto content or a file path, customizing chroma noise reduction.' },
    { name: 'sky_luma_denoise_tuning', description: 'Sets sky-specific luma denoise tuning parameters using text proto content or a path to the file.' },
    { name: 'sharpen_tuning', description: 'Specifies sharpening parameters for all lenses, either as text proto content or through a proto file path.' },
    { name: 'color_adjustment', description: 'Defines color adjustment parameters as text proto content or via a file, tuning color output.' },
    { name: 'demosaic_tuning', description: 'Applies demosaic tuning parameters, provided as text proto content or through a specified path.' },
    { name: 'use_new_color_adjustment', description: 'Enables the use of a new color adjustment method, potentially improving color accuracy.' },
    { name: 'use_prebaked_lookup_tables', description: 'Utilizes precomputed lookup tables instead of generating them in real-time, saving processing time.' },
    { name: 'demosaic_sharpness_factor', description: 'Adjusts the sharpness scaling factor in demosaic processing, affecting edge clarity.' },
    { name: 'shot_noise_factor_override', description: 'Overrides shot noise factors in Bayer channel order, applied across all frames regardless of capture ISO.' },
    { name: 'read_noise_var_override', description: 'Overrides read noise variance in Bayer channel order for all frames, disregarding ISO settings.' },
    { name: 'strip_dng_timestamps', description: 'Removes timestamps from DNG files, useful for generating consistent test files across runs.' },
    { name: 'strip_dng_makernote', description: 'Removes makernote data when creating DNG files, ensuring consistent test outputs between runs.' },
    { name: 'load_invalid_dngs', description: 'Attempts to load DNG files even if they are identified as invalid by the DNG SDK.' },
    { name: 'add_as_shot_color_calibration', description: 'Adds a third color calibration matrix based on ",0x22," as-shot white balance gains and the color correction matrix.' },
    { name: 'disable_adobe_profiles', description: 'Prevents embedding of Adobe DNG profiles for color calibration in output files, even if available.' },
    { name: 'image_save_numpy_checkpoint', description: 'Saves a named processing stage as a numpy array for checkpointing or debugging.' },
    { name: 'image_load_numpy_checkpoint', description: 'Loads a saved numpy array for a specified processing stage, enabling state restoration or analysis.' },
    { name: 'strip_exif_timestamps', description: 'Removes EXIF timestamps in JPEG or PNG outputs, ensuring consistent outputs for testing purposes.' }
];


const groupedRows = rows.reduce((acc: Record<string, typeof rows>, row) => {
    const firstLetter = row.name[0].toUpperCase();
    if (!acc[firstLetter]) {
      acc[firstLetter] = [];
    }
    acc[firstLetter].push(row);
    return acc;
  }, {});


export default function ParametersDictionary() {
    let main;
    const [expanded, setExpanded] = useState<string | false>(false);

    const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };

    main = (
        <Box
      id="hero"
      sx={(theme) => ({
        width: '100%',
        backgroundImage:
          theme.palette.mode === 'light'
            ? 'linear-gradient(180deg, #CEE5FD, #FFF)'
            : `linear-gradient(#02294F, ${alpha('#090E10', 0.0)})`,
        backgroundSize: '100% 20%',
        backgroundRepeat: 'no-repeat',
      })}
    >
        <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'left',
          pt: { xs: 14, sm: 20 },
          pb: { xs: 3, sm: 8 }
        }}
      >
        <Box 
        sx={{ 
            width: '100%', 
            display: 'flex', 
            justifyContent: 'flex-start', 
            paddingBottom: 2, 
            paddingLeft: 1 
        }}
        >
        <Breadcrumbs 
            aria-label="breadcrumb" 
            sx={{
            color: 'text.secondary',
            '& .MuiBreadcrumbs-separator': {
                color: 'text.disabled'
            }
            }}
        >
            <Link 
            href="/parameters-bsg" 
            sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                fontWeight: 500, 
                color: 'text.primary', 
                textDecoration: 'none', 
                '&:hover': {
                color: 'text.secondary', 
                textDecoration: 'none' 
                }
            }}
            >
            <NavigateBeforeIcon sx={{ fontSize: 20, marginRight: 0.5 }} /> 
            Back
            </Link>
        </Breadcrumbs>
        </Box>


        <Stack spacing={2} useFlexGap sx={{ width: { xs: '100%', sm: '70%' } }}>
          <Typography
            variant="h1"
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              alignSelf: 'left',
              textAlign: 'left',
              fontSize: 'clamp(3.5rem, 10vw, 4rem)',
            }}
          >
            Parameters&nbsp;
            <Typography
              component="span"
              variant="h1"
              sx={{
                fontSize: 'clamp(3rem, 10vw, 4rem)',
                color: (theme) =>
                  theme.palette.mode === 'light' ? 'primary.main' : 'primary.light',
              }}
            >
              Dictionary
            </Typography>
          </Typography>
          <Typography
            textAlign="left"
            color="text.secondary"
            sx={{ alignSelf: 'left', fontSize: '16px' }}
          >
            This section provides an alphabetical list of all parameters with brief descriptions:
          </Typography>
        </Stack>
        
        </Container>

        <Container
        sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            pt: { xs: 3, sm: 5 },
            pb: { xs: 3, sm: 8 },
            borderBottom: '2px solid',
            borderColor: 'divider'
          }}>
            <Stack spacing={2} useFlexGap sx={{ width: { xs: '100%', sm: '70%' } }}>
            <Box sx={{ width: '100%' }}>
        {Object.keys(groupedRows).sort().map((letter) => (
          <React.Fragment key={letter}>
            <Typography
              variant="h5"
              sx={{
                mt: 4,
                mb: 2,
                color: 'text.secondary',
                fontWeight: 'bold',
                textAlign: 'left'
              }}
            >
              {letter}
            </Typography>
            {groupedRows[letter].map((row, index) => (
              <Accordion
                key={row.name}
                expanded={expanded === `${letter}-${index}`}
                onChange={handleChange(`${letter}-${index}`)}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`${letter}-${index}-content`}
                  id={`${letter}-${index}-header`}
                >
                  <Typography component="h3" variant="subtitle2">{row.name}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography
                    variant="body2"
                    gutterBottom
                    sx={{ maxWidth: { sm: '100%', md: '90%' }, textAlign: 'left' }}
                  >
                    {row.description}
                  </Typography>
                </AccordionDetails>
              </Accordion>
            ))}
          </React.Fragment>
        ))}
            </Box>
            </Stack>
        </Container>
        <Footer/>
    </Box>
    );
    return(
        <CommonPage main={main}/>
    );
}