import React from 'react';
import { Box, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';
import TuneIcon from '@mui/icons-material/Tune';

export default function RecommendedParametersList ({ rows }: { rows: { primary: string; secondary: string }[] }) {
  return (
    <Box sx={{pt: { xs: 4, sm: 5 }, pb: { xs: 4, sm: 5 }}}>
      <Typography variant="h5" gutterBottom>
        <b>Recommended Parameters:</b>
      </Typography>
      <List>
        {rows.map((item, index) => (
          <ListItem key={index}>
            <ListItemIcon>
              <TuneIcon />
            </ListItemIcon>
            <ListItemText primary={item.primary} secondary={item.secondary} />
          </ListItem>
        ))}
      </List>
    </Box>
  );
}

