import React, { useEffect } from 'react';
import { createTheme, ThemeProvider, PaletteMode, CssBaseline } from '@mui/material';
import AppAppBar from './AppAppBar';
import getLPTheme from './getLPTheme';

export default function CommonPage(props: { main: any }) {
  const [mode, setMode] = React.useState<PaletteMode>(() => {
    return (localStorage.getItem('theme') as PaletteMode) || 'light';
  });

  const [showCustomTheme, setShowCustomTheme] = React.useState(true);
  const LPtheme = createTheme(getLPTheme(mode));
  const defaultTheme = createTheme({ palette: { mode } });

  const toggleColorMode = () => {
    setMode((prev) => {
      const newMode = prev === 'dark' ? 'light' : 'dark';
      localStorage.setItem('theme', newMode); // Сохранение выбранной темы в localStorage
      return newMode;
    });
  };

  const toggleCustomTheme = () => {
    setShowCustomTheme((prev) => !prev);
  };

  useEffect(() => {
    localStorage.setItem('theme', mode);
  }, [mode]);

  return (
    <ThemeProvider theme={showCustomTheme ? LPtheme : defaultTheme}>
      <CssBaseline />
      <AppAppBar mode={mode} toggleColorMode={toggleColorMode} />
      {props.main}
    </ThemeProvider>
  );
}
