import { Container, Stack, Typography } from "@mui/material";

export default function SetupsIntroduction() {
    return(
        <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'left',
          pt: { xs: 14, sm: 20 },
          pb: { xs: 3, sm: 8 }
        }}
      >
        <Stack spacing={2} useFlexGap sx={{ width: { xs: '100%', sm: '100%' } }}>
          <Typography
            variant="h1"
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              alignSelf: 'center',
              textAlign: 'center',
              fontSize: 'clamp(3.5rem, 10vw, 4rem)',
            }}
          >
            Frequently Used&nbsp;
            <Typography
              component="span"
              variant="h1"
              sx={{
                fontSize: 'clamp(3rem, 10vw, 4rem)',
                color: (theme) =>
                  theme.palette.mode === 'light' ? 'primary.main' : 'primary.light',
              }}
            >
              Combinations
            </Typography>
          </Typography>
          <Typography
          variant="h4"
            textAlign="left"
            color="text.secondary"
            sx={{ alignSelf: 'left', fontSize: '16px' }}
          >
            <b>Introduction to Parameter Combinations</b>
          </Typography>
          <Typography
            textAlign="left"
            color="text.secondary"
            sx={{ alignSelf: 'left',  fontSize: '16px', width: { xs: '100%', sm: '80%' }}}
          >
            Each type of photography benefits from specific parameter settings that bring out the best in each image. Below is a guide to ideal parameter combinations for various photography styles, such as portraits, landscapes, night shots, macro photography, and more. Using these suggestions as starting points, you can further adjust individual settings based on your unique environment and subject.         
          </Typography>
        </Stack>
        </Container>
    );
}